<template>
  <el-dialog 
    :title="itemId ? '修改':'新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="agreementName" label="联系人" verify>
          <el-input v-model="formData.agreementName" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item prop="agreementContent" label="协议内容" verify>
          <el-input type="textarea" :rows="10" v-model="formData.agreementContent" placeholder="请输入协议内容"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAgreement()
      }
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  // mounted(){
  //   this.visible && setTimeout(() => this.newVisible = this.visible, 100)
  //   this.itemId && this.getAgreement()
  // },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        return 
        let res = null
        if(this.itemId){
          res = await updataUser(this.formData)
        }else{
          res = await addUser(this.formData)
        }
        this.loading = false
        this.$message.success('操作成功')
        this.$emit('reload')
        this.handleClose()
      })
    },
    getAgreement(){
      this.loading = true
      
      this.formData = {
        agreementName: '我是协议名称',
        agreementContent: '我是协议内容'
      }
      setTimeout(() => this.loading = false, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>