<template>
  <div>
    <gl-title title="协议文本管理"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 新增 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="agreementName" label="协议名称"></el-table-column>
        <el-table-column prop="agreementContent" label="协议内容" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="mClickAction('mUpdateVisible', scope.row.id)"> 编辑 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.id)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId"/>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
export default {
  components: {update,preview},
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun 
    this.mGetList()
  },
}
</script>

<style>

</style>