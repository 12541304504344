<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
      <el-form-item prop="agreementName" label="联系人">
        <div> {{formData.agreementName}} </div>
      </el-form-item>
      <el-form-item prop="agreementContent" label="协议内容">
        <el-input 
          type="textarea" 
          :rows="10" 
          v-model="formData.agreementContent" 
          placeholder="请输入协议内容" 
          disabled>
        </el-input>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAgreement()
      },
    }
  },
  methods: {
    async getAgreement(){
      this.loading = true
      
      this.formData = {
        agreementName: '我是协议名称',
        agreementContent: '我是协议内容我是协议内容我是协议内容我是协议内容 我是协议内容'
      }
      setTimeout(() => this.loading = false, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>